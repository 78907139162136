import React, {useEffect} from 'react'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Obfuscate from 'react-obfuscate';

import Seo from "../components/Seo"
import Splitting from "splitting";
import ScrollOut from "scroll-out";

import H1 from '../components/H1';
 
// markup
const IndexPage = ({data}) => {
  const {
    wpPage: { title, content, team, seo, featuredImage },
  } = data

  useEffect(() => {
    // do something
    Splitting();
    const scroll = ScrollOut({once:true});

    setTimeout(() => {
      scroll.update();
    },300);

    return () => {
      scroll.teardown();
    }
  }, []);

  return (
    <>
    <Seo title={seo.title} description={seo.metaDesc} />
      <H1 title={title } />
      
    

       <div  className="container-fluid mb-20 mb-md-40">

       { featuredImage && 
            <GatsbyImage  image={getImage(featuredImage.node.localFile)} alt="Fint Digital Design" className="mb-5 mb-md-10" />
          }

        <div data-scroll="out" className="col-md-6 offset-md-6 col-xl-5 fade-in">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    


      <div className="container-fluid my-10 my-md-20  ">
      <h2 className="h1 mb-5 mb-md-10">
            Kontakt
          </h2>

          <div data-scroll="out" className="col-md-6 offset-md-6 col-xl-5 fade-in">
            <p>Ob Start-up, Firma, Organisation oder Agentur – wir freuen uns darauf Sie kennenzulernen. Kontaktieren Sie uns für ein unverbindliches Erstgespräch!</p>

             <div className="btn btn-dark btn-lg text-center rounded-0 px-5">
              <Obfuscate email="mail@fint.ch" />
            </div>     
          </div>
        </div>
     
 



      
      <div className="container-fluid">
          
          <h2 className="h1 my-10 my-md-20">
            Team
          </h2>

        <div className="row">
          {team.team?.map((person, index) => (
            <div data-scroll="out" className="fade-in col-md-4  ">
                <GatsbyImage  image={getImage(person.image.localFile)} alt={person.name} className="mb-3" />
                <h2 className="normal">{person.name}</h2>
                <div className="small" dangerouslySetInnerHTML={{ __html: person.infotext }} />
            </div>
          ))}
       </div>
      </div>
    </>
  )
}


export const query = graphql`
{
  wpPage(databaseId: {eq: 686}) {
    title
    content
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    team {
      team {
        name
        infotext
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    seo {
      title
      metaDesc
    }
  }
}
`

export default IndexPage
